import React from 'react';
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import Container from '@/components/Container';

interface TextLayoutProps {
  title: string;
  children?: React.ReactNode;
}

const TextLayout: React.FunctionComponent<TextLayoutProps> = ({
  children,
  title,
}) => {
  return (
    <>
      <div className="text-inner-hero-wrap">
        <Header />
        <Container>
          <div className="page-title">{title}</div>
        </Container>
      </div>
      <main>
        <div className="text-inner-content-wrap">{children}</div>
      </main>
      <Footer />
    </>
  );
};

export default TextLayout;
